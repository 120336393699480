<template>
  <div class="service">
    <PageHeader :navData="navData"></PageHeader>
    <!-- 内容区域 -->
    <div class="content">
      <div class="center">
        <div class="right">
          <div class="msg-box animate__animated animate__fadeInUp">
            <img src="../../assets/img/call.png">
            <h6>{{$t('afterSalesService')}}</h6>
            <p>{{tel}}</p>
          </div>
          <div class="msg-box animate__animated animate__fadeInUp">
            <img src="../../assets/img/email.png">
            <h6>{{$t('serviceEmail')}}</h6>
            <p>{{email}}</p>
          </div>
        </div>
        <!-- 新增地图功能 -->
        <div class="left animate__animated animate__fadeInUp">
          <!-- <img src="../../assets/zsimg/map.png"> -->
          <iframe src="/Bmap.html" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'service',
  data(){
    return {
      tel:'',
      email:'',
      // 导航数据
      navData:{
        navigationDescribe:'',
        navigationImg:'',
        navigationName:''
      },
    }
  },
  created(){
    this.getNavImg()
    this.getData()
  },
  methods:{
    // 获取导航信息
    getNavImg(){      
      this.$axios.post(
  	    '/officialweb/data/getGwNavigation',{
          navigationType:'5'
        }
  	  ).then(res=>{      
        this.navData.navigationDescribe=res.data.navigationDescribe
        this.navData.navigationImg=res.data.navigationImg
        this.navData.navigationName=res.data.navigationName
  	  }).catch(()=>{
      })
    },
     // 获取首页的联系方式
    getData(){
      this.$axios.post(
  	    '/officialweb/data/getGwContactUsBy'
  	  ).then(res=>{
        this.tel=res.data.afterSalesTel
        this.email=res.data.contactMail
  	  }).catch(()=>{
      })
    },
  }
}
</script>

<style lang="less" scoped>
@mainColor:#E6031A;
@blueColor:#004EA2;
// #004EA2;
@mainSize:1400px;



.newsCenter{
  width: 100%;
  min-width: @mainSize;
  overflow: hidden;
}
.content{
  width: @mainSize;
  margin: 0 auto;  
  margin-top: 50px;
  .center{
    width: 100%;
    height: 560px;
    overflow: hidden;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    .right{
      width: 480px;
      height: 100%;
      .msg-box{
        position: relative;
        width: 410px;
        height: 160px;
        background-color: #f7f7f7;
        border-left: 4px solid #004ea2;
        padding-left: 12px;
        padding-top: 10px;
        margin-top: 70px;
        text-align: left;
        img{
          width: 40px;
          height: 40px;
        }
        h6 {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 15px;
          margin-top: 20px;
        }
        p{
          font-size: 24px;
          line-height: 30px;
          color: #004ea2;
          margin-bottom: 15px;
        }
      }
    }
    .left{
      width: 800px;
      height: 100%;
      padding: 60px 0;
      iframe{
        width: 100%;
        height: 400px;
      }
    }
  }
}

</style>
